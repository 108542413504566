<template>
  <div class="constrain part">
    <div class="row-12 gutter-12">
      <div
        v-for="article in newsSelection"
        :key="article.id"
        class="md:col-4"
      >
        <Go
          :to="article.newsbeitragpdf[0]?.link"
          class="flex-col"
          target="_blank"
        >
          <div class="article">
            <img class="article-image" :src="article.bild.large" :alt="article.bild.alt">
            <div class="article-content flex flex-col bg-white">
              <h3 class="article-title h3 mb-4" v-html="article.titel" />
              <p class="my-4 text-lg" v-html="article.datum" />
              <p class="teasertext mb-8 md:mb-16" v-html="article.teasertext" />
              <div class="flex items-center mt-auto">
                <p class="buttontext uppercase font-bold text-blue tracking-wider mr-2" v-html="payload.weiterlesen" />
                <ArrowRight class="arrow" />
              </div>
            </div>
          </div>
        </Go>

      </div>
    </div>
    <div class="flex justify-center mt-12 md:mt-24">
      <Button
        :link="payload.buttonlink"
        :text="payload.alleNewsAnsehen"
        class="mx-auto my-0 block"
      />
    </div>
  </div>
</template>

<script>

import ArrowRight from '../../assets/images/Pfeil.svg';
import Button from '../utils/Button.vue';

export default {
  components: {
    ArrowRight,
    Button,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      newsToShow: 3,
    };
  },
  computed: {
    newsSelection() {
      return this.payload.news.slice(0, this.newsToShow);
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>
.article {
  height: 100%;
  background-color: var(--color-white);
  @include hover {
    .arrow {
      transform: translateX(0.5rem);
    }
    // .article-title {
    //   background-size: 100% 2px;
    // }
  }
}

.arrow {
  transition: transform 0.25s ease-out;
  width: px(18);
  height: px(18);
  color: var(--color-blue);
}

.article-image {
  height: px(250);
  max-height: px(250);
  width: 100%;
  object-fit: cover;
  @screen sm {
    height: px(400);
    max-height: px(400);
  }
}

.article-content {
  padding: 2rem 1rem 1rem 1rem;
  height: calc(100% - 250px);
  @screen sm {
    padding: 3rem 2rem 2rem 2rem;
    height: calc(100% - 400px);
  }
}

.article-title {
  display: inline;
  background-image: linear-gradient(var(--color-navy), var(--color-navy));
  background-repeat: no-repeat;
  background-size: 0 px(2);
  background-position: 0 95%;
  transition: background-size 0.3s ease-out;
}

.teasertext {
  color: var(--color-navy);
  &:deep(p) {
    line-height: 1.3;
  }
}

.buttontext {
  font-size: px(16);
  @screen md {
    font-size: px(18);
  }
}
</style>
